//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapGetters } from 'vuex';
import tableConfig from './tableConfig';
import constant from '~/const';

export default {
  data() {
    return {
      loader: false,
      searchQuery: '',
      tableConfig,
      constant,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapState('user', ['user']),
    ...mapGetters('user', ['userSecurity']),

    data() {
      const nfaList = this.userSecurity;
      return nfaList.filter((e) => {
        const { searchQuery } = this;
        const method = this.$utils.getCaption(
          constant.users.NFA_SERVICE_TYPE_LIST,
          e.nfaServiceType,
        );
        const sections = this.getNfaSectionTypeList(e.nfaSectionTypeList);
        return (
          method.toUpperCase().includes(searchQuery.toUpperCase())
          || sections.toUpperCase().includes(searchQuery.toUpperCase())
        );
      });
    },

    preparedData() {
      const { data } = this;
      return data.filter((e) => e.enabled);
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'confirmAction',
    ]),
    ...mapActions('user', {
      silentLoadUserAction: 'silentLoadUser',
    }),
    ...mapActions('userSecurityHistoryNfa', ['manageUser2Fa', 'disableUser2Fa']),

    async loadData() {
      const { id } = this.user;
      this.setGeneralProgress(true);
      this.loader = true;
      await this.silentLoadUserAction(id);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    getNfaSectionTypeList(list) {
      const { getCaption } = this.$utils;
      const { NFA_SECTION_TYPE_LIST } = constant.users;
      const nfaSectionTypeList = list.map((e) => getCaption(NFA_SECTION_TYPE_LIST, e));
      return nfaSectionTypeList.join(', ');
    },

    async removeNfa({ serviceType, nfaSectionTypeList }) {
      const { id } = this.user;

      this.confirmAction({
        text: 'Are you sure you want to disable method?',
        hasPrompt: true,
        callback: async ({ prompt }) => {
          this.loader = true;
          this.setGeneralProgress(true);
          try {
            await this.disableUser2Fa({
              userId: id,
              comment: prompt,
              serviceType,
              sections: nfaSectionTypeList,
            });
            await this.silentLoadUserAction(id);
            this.setSuccessNotification('MFA disabled');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.loader = false;
          this.setGeneralProgress(false);
        },
      });
    },
  },
};

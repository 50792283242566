//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import constant from '~/const';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        codeTypeList: [],
        twoFactorMethodList: [],
        dateCreatedFrom: '',
        dateCreatedTo: '',
        dateExpiryFrom: '',
        dateExpiryTo: '',
      },
      constant,
    };
  },

  computed: {
    ...mapState('userSecurity2FaCodes', ['filterLists']),
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  created() {
    this.setFilters({});
  },

  methods: {
    ...mapActions('userSecurity2FaCodes', ['setFilters']),
  },
};

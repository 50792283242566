//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import constant from '~/const';
import Filters from './UserSecurityLockHistoryFilters.vue';
import Pagination from '~/components/Pagination.vue';

import tableConfig from './tableConfig';

export default {
  components: {
    Filters,
    Pagination,
  },

  data() {
    return {
      loader: false,
      tableConfig,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('userSecurityLockHistory', ['page', 'pages', 'data', 'sort']),
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader', 'setSuccessNotification', 'setGeneralProgress']),
    ...mapActions('userSecurityLockHistory', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
    }),

    async loadData(data = {}) {
      this.loader = true;
      this.setGeneralProgress(true);
      await this.loadDataAction(data);
      this.loader = false;
      this.setGeneralProgress(false);
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    getLockType(type) {
      const { USER_LOCK_TYPE_LIST } = constant.users;
      const userLockType = USER_LOCK_TYPE_LIST.find((e) => e.id === type);
      if (userLockType) {
        return userLockType.caption;
      }
      return '';
    },
  },
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"whitelist"},[_c('div',{staticClass:"whitelist__filters"},[_c('v-row',[_c('v-col',{staticClass:"flex-grow-0"},[_c('v-text-field',{staticClass:"whitelist__address-text-field",attrs:{"dense":"","disabled":_vm.loader,"label":_vm.$t('IP address'),"outlined":"","placeholder":" "},model:{value:(_vm.ip),callback:function ($$v) {_vm.ip=$$v},expression:"ip"}})],1),_c('v-col',{staticClass:"flex-grow-0"},[_c('v-btn',{attrs:{"color":"success","height":"40px","outlined":"","disabled":!_vm.ip || _vm.loader},on:{"click":_vm.addIp}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-spacer'),_c('v-col',{staticClass:"flex-grow-0"},[_c('v-btn',{attrs:{"color":"error","disabled":_vm.loader || !_vm.data.length,"height":"40px","outlined":""},on:{"click":_vm.removeAllIp}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-close-circle ")]),_vm._v(" "+_vm._s(_vm.$t('Remove all'))+" ")],1)],1)],1)],1),_c('div',{staticClass:"whitelist__table"},[_c('UiTable',{attrs:{"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"287px"},on:{"sort":_vm.applySort},scopedSlots:_vm._u([{key:"ip",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"whitelist__address-cell"},[_vm._v(" "+_vm._s(row.ipAddress.replace('-', '–'))+" ")])]}},{key:"date",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.getDate(row.dateCreated))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$utils.getTime(row.dateCreated)))])]}},{key:"auths",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.auths)+" ")]}},{key:"delete",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.removeIp(row.ipAddress)}}},[_vm._v(" "+_vm._s(_vm.$t('Delete'))+" ")])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
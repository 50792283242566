export default [
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'IP',
    },
    cell: {
      type: 'slot',
      name: 'ip',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Date',
      sort: 'dateCreated',
    },
    cell: {
      type: 'slot',
      name: 'date',
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Type',
    },
    cell: {
      type: 'slot',
      name: 'type',
    },
  },
  {
    width: 5,
    header: {
      type: 'text',
      caption: 'API',
    },
    cell: {
      type: 'slot',
      name: 'api',
    },
  },
  {
    width: 5,
    header: {
      type: 'text',
      caption: 'MFA',
    },
    cell: {
      type: 'slot',
      name: 'mfa',
    },
  },
  {
    width: 8,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'slot',
      name: 'status',
    },
  },
  {
    width: 30,
    header: {
      type: 'text',
      caption: 'User agent',
    },
    cell: {
      type: 'slot',
      name: 'userAgent',
    },
  },
  {
    width: 20,
    header: {
      type: 'text',
      caption: 'Comment',
    },
    cell: {
      type: 'slot',
      name: 'comment',
    },
  },
];

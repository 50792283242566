//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import constant from '~/const';

export default {
  props: {
    className: { type: String, default: '' },
    data: { type: Object, default: () => ({}) },
  },

  computed: {
    ...mapGetters('login', ['getAdminById']),
    ...mapGetters('p2pArbitratorsList', ['getArbitratorById']),

    getLockCaption() {
      const { USER_LOCK_TYPE_LIST } = constant.users;
      const { type } = this.data;
      const lock = USER_LOCK_TYPE_LIST.find((e) => e.id === type);
      if (lock) {
        return this.$t(`const.${lock.caption}`);
      }
      return '';
    },

    getDateUntil() {
      const { until } = this.data;
      if (until) {
        return this.$utils.getFullDate(until);
      }
      return '';
    },

    getDateCreated() {
      const { dateCreated } = this.data;
      if (dateCreated) {
        return this.$utils.getFullDate(dateCreated);
      }
      return '';
    },

    getAdminOrArbitrator() {
      const { adminId, arbitratorId } = this.data;

      if (adminId !== null) {
        const admin = this.getAdminById(adminId);
        return admin ? admin.email : '';
      }
      if (arbitratorId !== null) {
        const arbitrator = this.getArbitratorById(arbitratorId);
        return arbitrator ? arbitrator.email : '';
      }
      return '';
    },

  },

  methods: {
    unlock() {
      this.$emit('unlock');
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import constant from '~/const';
import Filters from './UserSecurityHistoryNfaFilters.vue';
import Pagination from '~/components/Pagination.vue';

import tableConfig from './tableConfig';

export default {
  components: {
    Filters,
    Pagination,
  },

  data() {
    return {
      loader: false,
      tableConfig,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),
    ...mapGetters('login', ['getAdminById']),

    ...mapState('userSecurityHistoryNfa', ['page', 'pages', 'data', 'sort']),
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader', 'setSuccessNotification', 'setGeneralProgress']),
    ...mapActions('userSecurityHistoryNfa', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
    }),

    async loadData(data = {}) {
      this.loader = true;
      this.setGeneralProgress(true);
      await this.loadDataAction(data);
      this.loader = false;
      this.setGeneralProgress(false);
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    getNfaServiceTypeList(id) {
      const { NFA_SERVICE_TYPE_LIST } = constant.users;
      const nfaServiceType = NFA_SERVICE_TYPE_LIST.find((e) => e.id === id);
      if (!nfaServiceType) return '';

      return nfaServiceType.caption;
    },

    getAdmin(adminId) {
      const admin = this.getAdminById(adminId);
      if (admin) {
        return admin.email;
      }
      return this.$t('unknown');
    },

    getNfaSectionTypeList(list) {
      const { getCaption } = this.$utils;
      const { NFA_SECTION_TYPE_LIST } = constant.users;
      const nfaSectionTypeList = list.map((e) => getCaption(NFA_SECTION_TYPE_LIST, e));
      return nfaSectionTypeList.join(', ');
    },
  },
};

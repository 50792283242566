var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"active-fa"},[_c('div',{staticClass:"active-fa__filters"},[_c('v-row',[_c('v-col',[_c('v-text-field',{staticClass:"active-fa__search",attrs:{"dense":"","label":_vm.$t('Search'),"outlined":"","placeholder":" "},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1)],1),_c('div',{staticClass:"active-fa__table"},[_c('UiTable',{attrs:{"data":_vm.preparedData,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"fixed-header":"","height":"287px"},scopedSlots:_vm._u([{key:"method",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.getCaption(_vm.constant.users.NFA_SERVICE_TYPE_LIST, row.nfaServiceType))+" ")]}},{key:"ip",fn:function(ref){
var row = ref.row;
return [_c('span',{class:{'error--text': row.ipAddressPerc <= 3}},[_vm._v(" "+_vm._s(row.ipAddress)+" ")])]}},{key:"sections",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"pt-2 pb-2"},[_vm._v(" "+_vm._s(_vm.getNfaSectionTypeList(row.nfaSectionTypeList))+" ")])]}},{key:"created",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.getDate(row.dateEnabled))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$utils.getTime(row.dateEnabled)))])]}},{key:"remove",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"error","disabled":row.isDisabledRemove,"outlined":""},on:{"click":function($event){return _vm.removeNfa(row)}}},[_vm._v(" "+_vm._s(_vm.$t('Remove'))+" ")])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
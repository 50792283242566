import constant from '~/const';

const { USER_LOCK_TYPE_LIST } = constant.users;

const blockList = USER_LOCK_TYPE_LIST.map((e) => ({
  caption: e.caption,
  action: 'block',
  payload: e.id,
}));

export default blockList;

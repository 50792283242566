export default [
  {
    width: 25,
    header: {
      type: 'text',
      caption: 'IP',
    },
    cell: {
      type: 'slot',
      name: 'ip',
    },
  },
  {
    width: 25,
    header: {
      type: 'text',
      caption: 'Created',
      sort: 'dateCreated',
    },
    cell: {
      type: 'slot',
      name: 'date',
    },
  },
  {
    width: 25,
    header: {
      type: 'text',
      caption: 'Auths',
      sort: 'dateCreated',
    },
    cell: {
      type: 'slot',
      name: 'auths',
    },
  },
  {
    width: 25,
    header: {
      type: 'text',
      caption: '',
    },
    cell: {
      type: 'slot',
      name: 'delete',
    },
  },
];

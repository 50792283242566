export default [
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Method',
    },
    cell: {
      type: 'slot',
      name: 'method',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'IP',
    },
    cell: {
      type: 'slot',
      name: 'ip',
    },
  },
  {
    width: 35,
    header: {
      type: 'text',
      caption: 'Sections',
    },
    cell: {
      type: 'slot',
      name: 'sections',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Created',
    },
    cell: {
      type: 'slot',
      name: 'created',
    },
  },
  {
    width: 20,
    header: {
      type: 'text',
      caption: '',
    },
    cell: {
      type: 'slot',
      name: 'remove',
    },
  },
];

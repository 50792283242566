export default [
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Service',
    },
    cell: {
      type: 'slot',
      name: 'service',
    },
  },
  {
    width: 9,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'slot',
      name: 'status',
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'IP',
    },
    cell: {
      type: 'slot',
      name: 'ip',
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Date',
      sort: 'dateCreated',
    },
    cell: {
      type: 'slot',
      name: 'date',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Action',
    },
    cell: {
      type: 'slot',
      name: 'action',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Admin',
    },
    cell: {
      type: 'slot',
      name: 'admin',
    },
  },
  {
    width: 25,
    header: {
      type: 'text',
      caption: 'Sections',
    },
    cell: {
      type: 'slot',
      name: 'sections',
    },
  },
];

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-security-log"},[_c('div',{staticClass:"user-security-log__filters"},[_c('div',{staticClass:"d-flex"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}}),_c('GetReport',{attrs:{"type":_vm.constant.reports.REPORT_TYPE.USER_ACTIONS,"command":_vm.requestData}})],1)]),_c('div',{staticClass:"user-security-log__table"},[_c('UiTable',{attrs:{"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"287px"},on:{"sort":_vm.applySort},scopedSlots:_vm._u([{key:"ip",fn:function(ref){
var row = ref.row;
return [(row.ipAddress)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"border-bottom":"1px dotted"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(row.ipAddress)+" ")])]}}],null,true)},[_c('div',{},[_vm._v(" "+_vm._s(_vm.currentLocation(row))+" ")])]):_c('span',[_vm._v("–")])]}},{key:"date",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.getDate(row.dateCreated))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$utils.getTime(row.dateCreated)))])]}},{key:"type",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.getCaption(_vm.constant.user.USER_ACTION_TYPE_LIST, row.userActionType))+" ")]}},{key:"comment",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.getAdmin(row.adminId))+" ")]),_vm._v(" "+_vm._s(row.comment)+" ")]}},{key:"api",fn:function(ref){
var row = ref.row;
return [(row.appName)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"border-bottom":"1px dotted"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('Yes'))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(row.appName))])])]:_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$t('No')))])]}},{key:"mfa",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.nfaServiceType || '–')+" ")]}},{key:"status",fn:function(ref){
var row = ref.row;
return [(row.success)?_c('span',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.$t('Successful'))+" ")]):_vm._e(),(row.success === false)?_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$t('Unsuccessful'))+" ")]):_vm._e(),(row.enabled)?_c('span',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.$t('Enabled'))+" ")]):_vm._e(),(row.enabled === false)?_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$t('Disabled'))+" ")]):_vm._e()]}},{key:"userAgent",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.userAgent)+" ")]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('Pagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
// import Filters from './UserSecurityIpWhitelistFilters.vue';
// import Pagination from '~/components/Pagination.vue';

import tableConfig from './tableConfig';

export default {
  components: {
    // Filters,
    // Pagination,
  },

  data() {
    return {
      loader: false,
      ip: '',
      tableConfig,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('user', ['user']),
    ...mapState('userSecurityIpWhiteList', ['page', 'pages', 'data', 'sort']),
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setSuccessNotification',
      'setErrorNotification',
      'setGeneralProgress',
    ]),
    ...mapActions('userSecurityIpWhiteList', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      adminSaveIpAddressAction: 'adminSaveIpAddress',
      deleteIpAddressFromWhitelistAction: 'deleteIpAddressFromWhitelist',
      deleteAllIpAddressFromWhitelistAction: 'deleteAllIpAddressFromWhitelist',
    }),

    async loadData(data = {}) {
      this.loader = true;
      this.setGeneralProgress(true);
      await this.loadDataAction(data);
      this.loader = false;
      this.setGeneralProgress(false);
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    async addIp() {
      const { id } = this.user;
      const { ip } = this;

      this.loader = true;
      this.setGeneralProgress(true);
      try {
        await this.adminSaveIpAddressAction({
          userId: id,
          ipList: [ip],
        });
        await this.loadDataAction();
        this.ip = '';
        this.setSuccessNotification('IP address added successfully');
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.loader = false;
      this.setGeneralProgress(false);
    },

    async removeAllIp() {
      const { id } = this.user;

      this.loader = true;
      this.setGeneralProgress(true);
      await this.deleteAllIpAddressFromWhitelistAction({
        userId: id,
      });
      await this.loadDataAction();
      this.loader = false;
      this.setGeneralProgress(false);
      this.setSuccessNotification('All IP address deleted successfully');
    },

    async removeIp(IpAddress) {
      const { id } = this.user;

      this.loader = true;
      this.setGeneralProgress(true);
      await this.deleteIpAddressFromWhitelistAction({
        userId: id,
        ipList: [IpAddress],
      });
      await this.loadDataAction();
      this.loader = false;
      this.setGeneralProgress(false);
      this.setSuccessNotification('IP address deleted successfully');
    },
  },
};
